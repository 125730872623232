import React from 'react'

class IconLink extends React.Component {

  render () {
    const { children, href, icon, target } = this.props
    return (
      <a href={href} className="inclusion-grape-hover pv2 link flex items-center fw6 pointer color-inherit" target={target}>
        <i className={`f3 mr2 ${icon}`}></i>
        {children}
      </a>
    )
  }

}

export default IconLink