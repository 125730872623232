import React, { Component } from 'react';
import ReactTable from "react-table";
import moment from 'moment';

class Companies extends Component {
  state = {
    companies: this.props.companies
  }

  updateTableData = (rowIndex, columnName, value) => {
    let tableData = _.cloneDeep(this.state.groups);
    tableData[rowIndex][columnName] = value;
    this.setState({data: tableData});
  }

  goToManagementPortal = (path) =>  window.location = path

  render(){
    const companies = this.state.companies;
    const numberOfCompanies = companies.length;
    const columns = [{
      Header: (
        <span>
          <span className="inclusion-grape light-inclusion-grape-hover mr2">
            Company Name
          </span>
          <i className="fas fa-sort-alpha-down">
          </i>
        </span>
      ),
      accessor: 'title',
      Cell: row => {
        return ( <span className="dark-gray">{row.original.title}</span> )
      },
      Filter: ({filter, onChange}) => (
        <input type='text'
               placeholder="Search by name"
               value={filter ? filter.value : ''}
               onChange={event => onChange(event.target.value)}
        />
      ),
      filterMethod: (filter, {_original: {title} }) => {
        let filterValue = filter.value.toLowerCase();
        let originalRow = String(title).toLowerCase();
        return originalRow.includes(filterValue);
      }
    }, {
      Header: (
        <span>
          <span className="inclusion-grape light-inclusion-grape-hover mr2">
            Date Created
          </span>
          <i className="fas fa-sort-alpha-down">
          </i>
        </span>
      ),
      accessor: 'created_at',
      width: 150,
      Cell: row => {
        return (
        <span className="dark-gray">{moment(row.original.created_at).format("D/M/Y")}</span>
        )
      },
      filterable: false,
    }, {
      Header: (''),
      accessor: 'path',
      Cell: row => {
        return (
          <span className="pointer">
            <button className="square-btn outlined-square-btn grape-btn small"
            onClick={() => this.goToManagementPortal(row.value)}>
            Manage Users
            <i className="ml1 fas fa-arrow-right ml2"></i>
            </button>
          </span>
        )
      },
      filterable: false,
      width: 300,
    },
  ]
    return (
      <div className="group-management">
        <h2 className="f4 fw5 pv2 flex justify-start">
          Total Companies: {numberOfCompanies}
        </h2>
        <ReactTable
        filterable
        data={this.state.companies}
        columns={columns}
        minRows={this.state.companies.length > 0 ? 0 : 1}
        className="shadow"
        />
      </div>
    )
  }
}
export default Companies;
