import React, { Fragment } from 'react'
import cn from 'classnames';
import asOpenable from '../../higher_order_components/asOpenable'

const HamburgerButton = (props) => {
  const { isOpen, toggle } = props
  const iconStyles = `f3 pointer fa pr3 ${cn({ 'fa-times': isOpen, 'fa-bars': !isOpen})}`
  const iconLabel = `${cn({ 'Hide': isOpen, 'Show': !isOpen})} Navigation Menu`
  return (
      <button
      className='bg-transparent bn mid-gray'
      type='button'
      onClick={toggle}
      aria-expanded={isOpen}
    >
      <i className={iconStyles} aria-label={iconLabel} />
    </button>
  )
}

const Drawer = (props) => {
  const { isOpen, close, children } = props
  return (
    <div className={`${cn({ 'dn': !isOpen })} absolute w-100 vh-100 z-behind beneath-parent right-0`}>
      <div className='absolute absolute--fill o-0' aria-hidden={true} onClick={close} />
      <nav className='absolute right-0 pt1-5rem pb3 bg-white w-100 bottom-shadow outer-space flex flex-column items-stretch'>
        {children}
      </nav>
    </div>
  )
}

const HamburgerMenu = (props) => {
  const { children, ...openableProps } = props
  return (
    <Fragment>
      <HamburgerButton {...openableProps} />
      <Drawer {...openableProps}>
        {children}
      </Drawer>
    </Fragment>
  )
}

export default asOpenable(HamburgerMenu)
