import React from 'react'

const TablePagination = (props) => {
  const { currentPage, pageCount, pageSizes, handlePageSelected, handlePageSizeChanged, handleNextPage, handlePrevPage } = props
  return (
    <nav className='flex justify-center pb-3rem pb-6rem-ns'>
      <div className='flex flex-wrap flex-wrap-st flex-nowrap-ns items-center justify-between mw-37rem-ns w-100'>
        <div className='order-3 order-0-ns pl3 pl0-ns pb3 pb0-ns'>
          <button className='square-btn outlined-square-btn grape-btn small link' type='button' onClick={handlePrevPage} disabled={currentPage === 1}>
            Prev
          </button>
        </div>
        <div className='flex items-center justify-center w-100 w-auto-ns'>
          <p className='mr2 dark-gray-5 f6'>
            Page
          </p>
          <input type='number' max={pageCount} value={currentPage} onChange={handlePageSelected} className='b--silver-chalice ba bw-0-03rem br-0-18rem w-auto mw3 pv2 outer-space fw6 f6 tl pl3'/>
          <p className='ml3 dark-gray-5 f6 flex flex-nowrap'>
            of
            <span className='outer-space fw6 ml3'>
              {
                pageCount
              }
            </span>
          </p>
        </div>
        <div className='order-1 w-100 w-auto-ns mb2 mb0-ns'>
          <div className='flex justify-center'>
            <select onChange={handlePageSizeChanged} className='b--silver-chalice ba bw-0-03rem br-0-18rem pv2 outer-space fw6 f6 tl pl3'>
              {
              pageSizes.map( size => <option key={size} value={size}>{size} rows</option>)
              }
            </select>
          </div>
        </div>
        <div className='order-3 pr3 pl0-ns pb3 pb0-ns'>
          <button className='square-btn outlined-square-btn grape-btn small link' type='button' onClick={handleNextPage} disabled={ currentPage === pageCount }>
            Next
          </button>
        </div>
      </div>
    </nav>
  )
}

export default TablePagination