import React from 'react';
import { ajax } from 'rxjs/ajax';

let ApiService = WrappedComponent => props => <WrappedComponent {...props} apiService={new Ajax()}/>

class Ajax {
  constructor() {
    this.token = document.getElementsByName('csrf-token')[0].content
  }

  getJson = (data, url) => {
    return this.jsonRequest(data, url, 'GET')
  }

  postJson = (data, url) => {
    return this.jsonRequest(data, url, 'POST')
  }

  patchJson = (data, url) => {
    return this.jsonRequest(data, url, 'PATCH')
  }

  deleteJson = (data, url) => {
    return this.jsonRequest(data, url, 'DELETE')
  }

  jsonRequest = (data, url, method) => {
    return ajax({
      url: url,
      method: method,
      body: data,
      headers: {
        'X-CSRF-Token': this.token,
        "Content-Type": "application/json"
      },
    })
  }
}

export default ApiService;
