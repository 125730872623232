import React, { createRef, Fragment } from 'react'
import ConfirmationModal from '../../shared/ConfirmationModal'
import asOpenable from '../../../higher_order_components/asOpenable'

const ActivateCourseButton = (props) => {
  const { heading, question, close, open, isOpen, courseDisabled } = props

  const confirmed = () => {
    submitRef.current.form.submit()
  }

  const openModal = (event) => {
    event.preventDefault()
    open()
  }

  let submitRef = createRef()

  return (
    <Fragment>
      <input type='submit'
          className='square-btn outlined-square-btn grape-btn small lh-1-6'
          rel='nofollow'
          onClick={openModal}
          value='Activate Course'
          ref={ submitRef }
          disabled={ !courseDisabled || false }
        />
        <ConfirmationModal
          open={isOpen}
          onClose={ close }
          onConfirm={ confirmed }
          heading={ heading || 'Are you sure you want to activate this course?' }
          question={ question || '' }
        />
    </Fragment>
  )
}

export default asOpenable(ActivateCourseButton)