import React, { Fragment } from 'react'

const AlphabeticalSortingIcons = {
  'asc': 'fa-sort-alpha-down-alt',
  'desc': 'fa-sort-alpha-down'
}

const GenericSortingIcons = {
  'asc': 'fa-sort-amount-down',
  'desc': 'fa-sort-amount-up'
}

const SortableHeading = (props) => {
  const {title, direction, path} = props
  const isAlphabetical = props.alphabetical || false
  const icon = isAlphabetical
    ? AlphabeticalSortingIcons[direction]
    : GenericSortingIcons[direction]

  return (
    <Fragment>
      <a href={path} className={'b no-underline outer-space'}>
        <span>
          <p className='di pr2'>{title}</p>
          <i className={`dark-gray-5 fas ${icon}`} ></i>
        </span>
      </a>
    </Fragment>
  )
}

export default SortableHeading