import React from 'react'
import Modal from 'react-responsive-modal'
import railsCsrfToken from '../../helpers/RailsCsrfToken'

const SingleCourseProgress = (props) => {
  const { isOpen, close, title, resetProgressUrl, courseId, userId, companyId } = props

  const resetProgressParams = () => {
    let queryString = `?course_id=${courseId}&user_id=${userId}`
    return companyId == null ? queryString : queryString + `&company_id=${companyId}`
  }

  return (
    <Modal open={ isOpen } onClose={ close } showCloseIcon={ false } classNames={{ modal: 'Modal mv0' }} focusTrapped={true} >
      <h2 className='pt0 ma0 pb2'>Are you sure?</h2>
      <p className='lh-copy'>
        Are you sure you want to reset this user’s progress? This will bring all their progress in the {title} down to 0.
      </p>
      <div className='flex flex-row justify-between pt3'>
        <button onClick={close} className='square-btn outlined-square-btn grape-btn small lh-1-6'>No</button>
        <form action={resetProgressUrl + resetProgressParams()} method="post">
          <input name="_method" type="hidden" value="delete" />
          <input name="authenticity_token" type="hidden" value={railsCsrfToken()} />
          <button type="submit" value="Delete" className='square-btn filled-square-btn grape-btn small lh-1-6'>Yes</button>
        </form>
      </div>
    </Modal>
  )
}

export default SingleCourseProgress