import React, {Component} from 'react'

const asOpenable = (WrappedComponent, initialState=false) =>{
  return class extends Component {
    state = {
      open: initialState,
    }

    open = () => this.setState({open: true})

    close = () => this.setState({open: false})

    toggle = () => this.setState(state => ({open: !state.open}))

    render = () => {
      return (
        <WrappedComponent isOpen={this.state.open} open={this.open} close={this.close} toggle={this.toggle} {...this.props}/>
      )
    }
  }
}


export { asOpenable }
export default asOpenable