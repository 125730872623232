import React from 'react';

const Dropdown = (props) => {
  const { options, name, currentValue, onChange } = props

  const handleOnChange = (event) => {
    onChange && onChange(event)
  }

  return (
    <div className="dropdown relative faIcon">
      <select 
        value={currentValue}
        onChange={handleOnChange}
        id={name}
        name={name}
      >
        {options.map((option, index) => <option key={index} value={option.value}>{option.content}</option>)}
      </select>
    </div>
  )
}

export default Dropdown