import React from 'react';
import Dropdown from './Dropdown';

const SortRowsSelect = (props) => {
  const {options} = props

  const findSelected = (options) => {
    const selected = options.find(opt => opt.selected == true)
    return !!selected ? selected.content : options[0].content
  }

  const changeRowSort = (event) => {
    window.location = options.find(option => option.value == event.target.value).url
  }

  return (
    <div className="flex flex-wrap flex-nowrap-ns items-center mb3 mt2 mt0-l mb0-l">
      <label className="f6 f5-ns fw5 fw6 outer-space pr2 pr3-ns pb2 pb0-ns" htmlFor="sort_by">Sort by</label>
      <Dropdown
        options={options}
        name={'sort_by'}
        currentValue={findSelected(options)}
        onChange={changeRowSort}  
      />
    </div>
  )

}
export default SortRowsSelect