import React, { Fragment } from 'react'

const AddUserLabel = (props) => {
  const { title } = props
  return (
    <label className='db dn-nst lh-1-6 fw5 f5 outer-space pb2'>
      { title }
    </label>
  )
}

const AddUserField = (props) => {
  const { defaultValue, value, field, onChange, error } = props
  return (
    <Fragment>
      <input
        name={`user_details[][${field}]`}
        className={`w-100 br2 ba pa3 v-mid f5 lh-1-6 dark-gray-5 border-box mb3 mb0-nst
                   ${error ? "b--tall-poppy-red" : "b--concrete-gray"} `}
        defaultValue={defaultValue}
        value={value}
        onChange={ (event)=> { onChange(field, event.target.value) } } />
    </Fragment>
  )
}

/*
* FieldError
* Input: title (string), error (array)
* Output: First item from error list. Title is added to the front if it doesn't start with a capital
*/
const FieldError = (props) => {
  const { title, error } = props
  return (
    <p className='tall-poppy-red fw5 mb0-nst mt0 mt2-nst flex lh-1-4'>
      <i className='fas fa-info-circle fa-lg pr2'></i>
      {error[0][0] === error[0][0].toLowerCase() && title} {error[0]}
    </p>
  )
}

const AddUserDetail = (props) => {
  const { detail, value, title, field, onChange, error } = props
  return (
    <div className='w-100 ph3-ns flex-none justify-around'>
      <AddUserLabel title={ title } />
      <AddUserField field={ field } defaultValue={ detail } value={ value } onChange={ onChange } error={ error } />
      {error && <FieldError title={ title } error={ error }></FieldError>}
    </div>
  )
}

const ClearButton = (props) => {
  const { onClick } = props
  return (
    <button className='bg-white bn pointer' onClick={onClick} type='button'>
      <i className='fa fa-times f1-5rem dark-gray-5'></i>
    </button>
  )
}

export {
  ClearButton
}

export default AddUserDetail