import React, { Fragment } from 'react'
import asOpenable from '../../higher_order_components/asOpenable'
import withApiService from '../../higher_order_components/withApiService'
import ConfirmationModal from '../shared/ConfirmationModal'

const DeleteArchivedSeatButton = (props) => {
  const { open, close, isOpen, path, apiService } = props

  const onClose = () => {
    props.close()
    window.location.reload()
  }

  const deleteAction = () => { apiService.deleteJson({}, path).subscribe(onClose) }

  return (
    <Fragment>
      <button
        className='square-btn outlined-square-btn well-read-btn pointer pa1-5rem pv3 ba br2 f6'
        rel='nofollow'
        onClick={open}
        type="button"
      >
       Delete
      </button>
      <ConfirmationModal
        open={isOpen}
        onClose={ close }
        onConfirm={ deleteAction }
        heading='Are you sure?'
        question={`Are you sure you want delete a user?`}
      />
    </Fragment>
  )
}

export default withApiService(asOpenable(DeleteArchivedSeatButton))