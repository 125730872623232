import React from 'react';
import Dropdown from './Dropdown';

const CourseFilterDropdown = (props) => {
  const { name, currentValue, options } = props

  const changeFilter = (event) => {
    window.location = options.find(option => option.value == event.target.value).url
  }

  return (
    <Dropdown
      options={options}
      name={name}
      currentValue={currentValue}
      onChange={changeFilter}
    />
  )

}
export default CourseFilterDropdown