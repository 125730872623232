import React, { Component } from 'react'
import withApiServivce from './withApiService'

const withProgress = (WrappedComponent) => {
  return class extends Component {
    state = {
      selectedCourse: null,
      progressCache: {},
      loading: true,
    }

    updateProgress = (courseId) => {
      const cachedProgress = this.state.progressCache[courseId]
      if(cachedProgress)
        this.setState({ selectedCourse: courseId })
      else
        this.fetchProgress(courseId)
    }

    fetchProgress = (courseId) => {
      const { progressPaths, apiService } = this.props
      const responseHandler = request => this.setProgressFromRequest(request.response, courseId)
      this.setState({ loading: true }, () => apiService.getJson({}, progressPaths[courseId]).subscribe(responseHandler))
    }

    setProgressFromRequest = (response, courseId) => {
      this.setState((state) => ({
        selectedCourse: courseId,
        loading: false,
        progressCache: {
          [courseId]: {
            completed: response['completed'],
            notStarted: response['not_started'],
            inProgress: response['in_progress']
          },
          ...state.progressCache,
        }
      }))
    }

    render = () => {
      const { progressPaths, apiService, ...componentProps } = this.props
      const { progressCache, selectedCourse, loading } = this.state
      return <WrappedComponent {...componentProps}
                               loading={loading}
                               progress={progressCache[selectedCourse]}
                               updateProgress={this.updateProgress} />
    }
  }
}

const wrapper = Component => withApiServivce(withProgress(Component))
export default wrapper