import React from 'react'

const ModuleChecklistItem = (props) => {
  const { title, completed } = props

  return (
    <div className="mv2 flex items-center">
      <i className={`mr1-5rem far fa-2x ${completed ? "fa-check-circle jade-green" : "fa-times-circle meteor-orange"}`}>
      </i>
      <p className="fw5 outer-space ">
        {title}
      </p>
    </div>
  )
}

export default ModuleChecklistItem