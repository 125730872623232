import React, { Fragment } from 'react'

export default function Pricing(props) {
  const { total } = props.pricing
  const { currentSeats, coursesCount } = props
  const prices = props.pricing.prices || [props.pricing]

  const PerHeadCost = () => {
    return (
      <Fragment>
        {total && prices.map((pricing) =>
          <p key={pricing.description} className='mv2 fw4 f6 lh-1-6 outer-space pricing-label' data-pricing-description={pricing.description}>
            {formatPrice(parseFloat(pricing.perUnit || 0).toFixed(2), pricing.description)}
        </p>
        )}
        <a className='db inclusion-grape light-inclusion-grape-hover fw4 f6 mt2' href={props.pricingHelpPath}>
          Need help with pricing?
        </a>
      </Fragment>
    )
  }

  const pluralize = (word, n) => `${word}${n == 1 ? '' : 's'}`
  const descriptions = {
    seats: (cost) => `$${cost} per additional seat`,
    courses: (cost) => `Access to ${coursesCount} ${pluralize('course', coursesCount)} for your ${currentSeats}
                        current ${pluralize('seat', currentSeats)} is a difference of $${cost}`
  }
  const formatPrice = (cost, description) => (descriptions[description] && descriptions[description](cost)) || `$${cost}`
  return (
    <Fragment>
      <h4 className='dark-gray-5 mt0 mb-1-5rem f4 lh-1-6'>
        Total cost
      </h4>
      <p className='mv0 f5 b inclusion-grape'>
        ${ parseFloat(total || 0).toFixed(2) } AUD
      </p>
      {props.planType == 'Company' ? <PerHeadCost /> : '' }
    </Fragment>
  )
}