import React, {Component} from "react"
import withLabel from "./withLabel"

class InputField extends Component {
  static defaultProps = { required: true }
  
  render () {
    const { styles, name, caption, required, ...inputProps } = this.props  
    const cleanStyles = (styles || '').replace('undefined', '')
    return <input
        className={`input-reset border-box w-100 tc-fifth-color fw7 ba b--light-silver pa3 br2 ${cleanStyles}`}
        name={name}
        id={name}
        required={required}
        {...inputProps}
    />
  }
}

export default InputField
export const LabelledField = withLabel(InputField)