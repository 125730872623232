import React, {Component, Fragment} from "react"
import CourseTabs from '../courses/CourseTabs';
import CompanyCourseProgressCard from './CompanyCourseProgressCard'
import withProgress from '../../higher_order_components/withProgress'

const CourseProgress = (props) => {
  return (
    <Fragment>
      <CourseTabs {...props} aria_nav_label='Course Navigation' />
      <CompanyCourseProgressCard {...props} />
    </Fragment>
  )
}

class CompanyCourseProgress extends Component {

  constructor(props){
    super(props);
      const selectedCourseIndex = this.findIndexFromCourseId(props.selectedCourse)
      this.state = {
        indexOfSelectedCourse: selectedCourseIndex
      }
    }

    componentDidMount = () => {
      this.props.updateProgress(this.props.courseDetails[this.state.indexOfSelectedCourse].id)
    }

    changeSelectedCourse = (index) => {
      const { updateProgress, courseDetails } = this.props
      this.setState({
        indexOfSelectedCourse: index
      }, () => updateProgress(courseDetails[index].id))
    }

    findIndexFromCourseId = (course_id) => {
      if(course_id == undefined) return 0

      const { courseDetails } = this.props
      const details = courseDetails.find((course) => course.id == course_id)
      return !!details ? courseDetails.indexOf(details) : 0
    }

    render () {
      const { indexOfSelectedCourse } = this.state
      return (
        <Fragment>
          <CourseProgress key={indexOfSelectedCourse} {...this.props} changeSelectedCourse={this.changeSelectedCourse} indexOfSelectedCourse={indexOfSelectedCourse}/>
        </Fragment>
      )
    }
}

export default withProgress(CompanyCourseProgress)