import React, { useMemo } from "react";
import cn from 'classnames';
import PropTypes from 'prop-types';

const TriStateCheckbox = (props) => {
  const { checkedState, className, ...CheckboxProps } = props;
  const checked = useMemo(() => (checkedState || 'false') !== 'false', [checkedState])

  const checkboxClassNames = cn(
    'tristate-checkbox checkbox-icon',
    { 
      'checked': checkedState == 'true',
      'indeterminate': checkedState == 'mixed'
    },
    className
  )

  return (
    <div className="tristate-checkbox-wrapper">
      <label className='tristate-checkbox'>
        <input
          type="checkbox"
          checked={checked}
          role="checkbox"
          readOnly={true}
          aria-checked={checkedState}
          ref={(input) => {
            if (input) {
              input.indeterminate = checkedState == 'mixed';
            }
          }}
          {...CheckboxProps}
        />
        <span className={checkboxClassNames}></span>
      </label>
    </div>
  );
}

TriStateCheckbox.propTypes = {
  checkedState: PropTypes.oneOf([undefined, 'true', 'false', 'mixed'])
}


export default TriStateCheckbox