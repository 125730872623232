import React, {Component} from 'react'
import ManagerAction from './ManagerAction'

const ManagerPromoteAction = (props) => {
  return (
    <ManagerAction
      heading='Are you sure?'
      question='Are you sure you want to make this user a manager?'
      { ...props }
    />
  )
}

export default ManagerPromoteAction