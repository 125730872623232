import React from 'react'
import ManagerAction from './ManagerAction'

const ResendInviteEmailsAction = (props) => {
  return (
    <ManagerAction
      heading='Are you sure?'
      question='Email invitations will only be sent to users who have not yet registered to the Inclusion Program.'
      { ...props }
    />
  )
}

export default ResendInviteEmailsAction