import React, { Fragment } from 'react'
import DeleteArchivedSeatButton from './DeleteArchivedSeatButton';
import PostButton from '../shared/PostButton';
import TriStateCheckbox from '../shared/TriStateCheckbox';

const ArchivedSeatRow = ({selected, seat, toggleSelect, displayActions, displayRestore, deletePath, restorePath}) => {
  const onClick = () => {
    toggleSelect(seat.id);
  }

  const handleKeyPress = (event) => {
    switch(event.key){
      case 'Space':
        toggleSelect(seat.id);
        break;
      case 'Enter':
        toggleSelect(seat.id);
        break;
      default:
        break;
    }
  }

  const RestoreButton = () => {
    return (
      <Fragment>
        {
          displayRestore && (
                <PostButton
                  className="square-btn filled-square-btn grape-btn pa1-5rem pv3 f6 br2 bn mr3"
                  path={restorePath}
                  copy='Are you sure you want to restore a user?'
                >
                  Restore
                </PostButton>
          )
        }
      </Fragment>
    )
  }

  return (
    <div className='archived_seat_row pv3 flex-ns ph4'>
      <div className='flex flex-wrap w-100 items-center w-50-ns pr3-ns'>
        <div className='w-20 w-10-ns f6 mv1'>
          <TriStateCheckbox
            onClick={onClick}
            onKeyPress={handleKeyPress}
            checkedState={selected ? 'true' : 'false'}
            readOnly={true}
          />
        </div>
        <div className='w-80 w-90-ns break-word f6 mv1'>
          {
            seat.email
          }
        </div>
      </div>
      <div className='flex w-100 w-50-ns justify-start justify-end-ns pt3 pt0-ns'>
        {
          displayActions && (
            <Fragment>
              <RestoreButton />
              <DeleteArchivedSeatButton path={deletePath}/>
            </Fragment>
          )
        }
      </div>
    </div>
  )
}

export default ArchivedSeatRow