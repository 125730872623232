import React, { Fragment } from 'react'
import NavLink from "./NavLink"

const NavLinkElements = (props) => {
    const { navLinks, className, activeClassName } = props

  return (
    <Fragment>
    {
      navLinks.map((link, i) =>
        <NavLink 
          key={i} 
          href={link.path} 
          icon={link.icon} 
          className={className} 
          activeClassName={activeClassName}
        >
          {link.text}
        </NavLink>)
    }
    </Fragment>
  )
}

export default NavLinkElements