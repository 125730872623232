import React from 'react'
import withPlan from '../../higher_order_components/withPlan'
import Pricing from './Pricing'

const QuantityInput = (props) => {
  const { pricing, seats, changeSeats } = props.plan
  return (
    <div className='flex w-100 justify-between mt3 mb4 mb5-ns mt4-m mt5-l'>
      <div className='w-50 w-auto-ns'>
        <h4 className='dark-gray-5 mt0 mb-1-5rem f4 lh-1-6'>
          Number of Seats to add
        </h4>
        <input
          name='quantity'
          type='number'
          min={props.min}
          defaultValue={seats || 0}
          onChange={(e) => changeSeats(e.target.value)}
          className='input-reset border-box tc-fifth-color fw7 ba b--light-silver pa3 br2 tr mw-6-25rem'
        />
        {props.children}
      </div>
      <div className='tr'>
        <Pricing pricing={pricing} pricingHelpPath={props.pricingHelpPath} planType={props.planType} {...props.plan} />
      </div>
    </div>
  )
}

QuantityInput.defaultProps = {
  min: 1
}

export default QuantityInput
export const Priced = withPlan(QuantityInput)