import React, {Component, Fragment} from "react"
import CourseTabs from './CourseTabs';
import CourseProgressCards from './CourseProgressCards'

import asOpenable from '../../higher_order_components/asOpenable'

const CourseProgress = (props) => {
  return (
    <Fragment>
      <p className="f5 outer-space fw7 mb4">Courses</p>
      <CourseTabs {...props} aria_nav_label='Course Navigation' />
      <CourseProgressCards {...props} />
    </Fragment>
  )
}

const NoCourseProgress = () => {
  return (
    <p className="f5 outer-space fw7 mb4"> There is no course progress to report yet. </p>
  )
}

class StudentCourseProgress extends Component {

  constructor(props){
    super(props);
      const selectedCourseIndex = this.findIndexFromCourseId(props.selectedCourse)
      this.state = {
        indexOfSelectedCourse: selectedCourseIndex
      }
    }

    changeSelectedCourse = (index) => {
      this.setState({
        indexOfSelectedCourse: index
      })
    }

    findIndexFromCourseId = (course_id) => {
      if(course_id == undefined) return 0

      const { courseDetails } = this.props
      const details = courseDetails.find((course) => course.id == course_id)
      return !!details ? courseDetails.indexOf(details) : 0
    }

    render () {
      const { courseDetails } = this.props
      const { indexOfSelectedCourse } = this.state
      return (
        <Fragment>
        {courseDetails ?
          <CourseProgress key={indexOfSelectedCourse} {...this.props} changeSelectedCourse={this.changeSelectedCourse} indexOfSelectedCourse={indexOfSelectedCourse}/> :
          <NoCourseProgress />
        }
      </Fragment>
      )
    }
}

export default asOpenable(StudentCourseProgress)