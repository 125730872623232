import React, {Component} from 'react'
import InputField from './InputField'
import withLabel from './withLabel'

class PasswordField extends Component {

  state = { contentsVisible: false }

  render () {
    const { styles, ...inputProps } = this.props
    const contentsVisible = this.state.contentsVisible
    const iconColors = contentsVisible ? 'inclusion-grape' : 'silver-chalice'
    const fieldType = contentsVisible ? 'text' : 'password'
    const toggleVisibility = () => this.setState({ contentsVisible: !contentsVisible })
    return <div className='relative'>
      <InputField {...inputProps} type={fieldType} styles={`pr5 ${styles}`} />
      <div className='absolute right-0 top-0 bottom-0 mr3 flex items-center w2'>
        <button type='button' className={`symbol-button pointer ${iconColors}`} aria-label='Show password' onClick={toggleVisibility}></button>
      </div>
    </div>
  }
}

export default withLabel(PasswordField)