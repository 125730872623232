import React from 'react'
import InputField from '../forms/InputField';

const TableSearch = (props) => {
  return (
     <div className="flex justify-between br-pill ba b--concrete-gray ph3 pv2 f5 mw-26-875rem-ns w-100 mb4">
       <InputField {...props} type='text' className='w-100 bn lh-copy' placeholder='Search'/>
     </div>
  )
}

export default TableSearch;