import React, { Fragment } from 'react'
import asOpenable from '../../../higher_order_components/asOpenable'
import withApiService from '../../../higher_order_components/withApiService'
import ConfirmationModal from '../../shared/ConfirmationModal'

const RemovePermissionButton = (props) => {
  const {open, close, isOpen, path, apiService, permission } = props

  const onClose = () => {
    props.close()
    window.location.reload()
  }

  const patchAction = () => { apiService.patchJson({}, path).subscribe(onClose) }

  return (
    <Fragment>
      <button
        className='pointer lh-1-6 f5 b bn bg-inherit v-mid'
        rel='nofollow'
        onClick={open}
        type="button"
      >
        <i className='fa fa-times' >
        </i>
      </button>
      <ConfirmationModal
        open={isOpen}
        onClose={ close }
        onConfirm={ patchAction }
        heading='Are you sure?'
        question={`Are you sure you want to remove this user as a ${permission}`}
      />
    </Fragment>
  )
}

export default withApiService(asOpenable(RemovePermissionButton))