import React, { Component } from 'react'
import withApiService from '../../higher_order_components/withApiService'
import asOpenable from '../../higher_order_components/asOpenable'
import ConfirmationModal from '../shared/ConfirmationModal'

const ManagerMethods = {
  'PATCH': 'patchJson',
  'POST': 'postJson',
  'DELETE': 'deleteJson'
}

class ManagerAction extends Component {
  patchAction = () => {
    const { path, apiService, method } = this.props
    apiService[ManagerMethods[method]]({}, path).subscribe((response) => { this.onClose(response.response)})
  }

  onClose = (response) => {
    this.props.close()
    if(!!response.redirect){
      window.location = response.redirect
    }
    else{
      window.location.reload()
    }
  }

  render = () => {
    const { label, open, isOpen, close, heading, question, style } = this.props
    return (
      <li>
        <button
          className='pointer db pa3 no-underline lh-1-6 f5 b outer-space inclusion-grape-hover bn bg-white'
          style={ style || {} }
          rel='nofollow'
          onClick={open}
          type="button"
        >
          { label }
        </button>
        <ConfirmationModal
          open={isOpen}
          onClose={ close }
          onConfirm={ this.patchAction }
          heading={ heading }
          question={ question }
        />
      </li>
    )
  }
}

export default withApiService(asOpenable(ManagerAction))
