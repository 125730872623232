import React, {Component} from 'react'
import ManagerAction from './ManagerAction'

const ManagerRemoveUserAction = (props) => {
  return (
    <ManagerAction
      heading='Are you sure?'
      question='Are you sure you want to delete this user?'
      style={ {color: '#C02425'} }
      { ...props }
    />
  )
}

export default ManagerRemoveUserAction