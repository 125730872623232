import React, { Component } from 'react'
import withApiServivce from './withApiService'

const withPlan = (WrappedComponent) => {
  return class extends Component {
    state = {
      bundleIds: this.props.bundleIds || [],
      seats: this.props.seats,
      pricing: {}
    }

    static defaultProps = { seats: 1, minSeats: 1, ownedBundleIds: [] }

    componentDidMount = () => {
      if(this.props.bundleIds || this.props.seats) {
        this.getPricing()
      }
    }

    validPlan = () => {
      const { seats, bundleIds } = this.state
      return !!(this.props.ownedBundleIds.length ? seats || bundleIds.length : seats && bundleIds.length)
    }

    selectBundle = (bundleId, selected) => [bundleId, ...selected]

    deselectBundle = (bundleId, selected) => selected.filter(id => id != bundleId)

    toggleSelection = (bundleId) => {
      const { bundleIds } = this.state
      let newSelections = bundleIds.includes(bundleId)
        ? this.deselectBundle(bundleId, bundleIds)
        : this.selectBundle(bundleId, bundleIds)

      this.setState({ bundleIds: newSelections }, this.getPricing)
    }

    changeSeats = (seats) => this.setState({ seats: parseInt(seats) }, this.getPricing)

    getPricing = () => this.validPlan() ? this.requestPricing() : this.setPricing({})

    setPricing = (pricing) => this.setState({ pricing: pricing })

    requestPricing = () => {
      const { apiService, pricesPath } = this.props
      const { seats, bundleIds } = this.state
      const requestData = { bundle_ids: bundleIds, quantity: seats }
      apiService.postJson(JSON.stringify(requestData), pricesPath).subscribe(this.setPricingFromRequest)
    }

    setPricingFromRequest = (request) => this.setPricing(request.response)

    render = () => {
      const plan = {
        isValid: this.validPlan(),
        toggleBundle: this.toggleSelection,
        changeSeats: this.changeSeats,
        currentSeats: this.props.currentSeats,
        bundlesCount: this.state.bundleIds.length,
        ...this.state
      }
      return <WrappedComponent {...this.props} plan={plan} />
    }
  }
}

const wrapper = Component => withApiServivce(withPlan(Component))
export default wrapper