import React, { useMemo, Fragment } from 'react'
import PostButton from '../shared/PostButton'
import TriStateCheckbox from '../shared/TriStateCheckbox';

const TableHead = (props) => {
  const {handleSelectAll, totalRows, selectedRows, available_seats, bulkDeletePath, bulkRestorePath} = props

  const selectAllState = useMemo(() => {
    if(totalRows == selectedRows.size) { return 'true' }
    if(selectedRows.size > 0) { return 'mixed' }
    return 'false'

  }, [selectedRows])

  const displayActions = useMemo(() => selectedRows.size > 0 , [selectedRows])
  const selectedCount = useMemo(() => selectedRows.size, [selectedRows])
  const displayRestoreButtons = useMemo(() => available_seats > 0, [available_seats])

  const handleKeyPress = (event) => {
    switch(event.key){
      case 'Space':
        handleSelectAll();
        break;
      case 'Enter':
        handleSelectAll();
        break;
      default:
        break;
    }
  }

  return (
    <div className='flex flex-wrap flex-nowrap-ns w-100 pa3 pv4-ns ph4'>
      <div className='flex flex-nowrap-ns w-100 w-50-ns pr3-ns'>
        <div className='flex items-center w-20 w-10-ns f6 b'>
          <TriStateCheckbox
            onClick={handleSelectAll}
            onKeyPress={handleKeyPress}
            checkedState={selectAllState}
            className='grape'
            readOnly={true}
          />
        </div>

        <div className='flex items-center w-80 w-90-ns f6 b'>
          Email
        </div>
      </div>
      <div className='w-100 w-50-ns b flex flex-wrap flex-nowrap-ns justify-center justify-end-ns items-center'>
        {
          displayActions && (
          <Fragment>
            <div className='mr2-ns w-100 w-auto-ns mt2 mt0-ns f6'>
              {selectedCount} users(s) of {totalRows} selected
            </div>
            <div className='flex flex-nowrap w-100 w-auto-ns mt2 mt0-ns'>
              {
                displayRestoreButtons && (
                  <PostButton
                    className="square-btn filled-square-btn grape-btn pa1-5rem pv3 f5 br2 bn mr2"
                    path={bulkRestorePath}
                    copy={`Are you sure you want to restore ${selectedCount} user(s)?`}
                    body={{archived_seat_ids: [...selectedRows]}}
                  >
                    Restore
                  </PostButton>
                )
              }
              <PostButton
                className='square-btn outlined-square-btn well-read-btn pa1-5rem pv3 ba br2 f6'
                path={bulkDeletePath}
                copy={`Are you sure you want to delete ${selectedCount} user(s)?`}
                body={{archived_seat_ids: [...selectedRows]}}
              >
                Delete
              </PostButton>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default TableHead;