import React from "react"
import inclusionLogo from "../../images/inclusion.png";

export default function Header(props) {
  const { homePath, authLink, children, header_height } = props
  const height_for_header = header_height ? header_height : 'slim_header_height'
  return (
    <div className={`${height_for_header} w-100`}>
      <header className="flex justify-around items-center flex-wrap w-100 fixed z-9999 bg-third-color">
        <div className="bg-black w-100 flex justify-around">
          <div className="ph3 ph-1-5rem-ns bg-black flex justify-between items-center mw-78rem w-100">
            <a href={homePath || '/'}>
              <img className='h2 h2-5-ns ph2 pv3' src={inclusionLogo} />
            </a>
            {authLink}
          </div>
        </div>
        {children}
      </header>
    </div>
  )
}