import React from 'react'
import IconLink from './IconLink';

class NavLink extends React.Component {
  render () {
    const { location, href, target, className, activeClassName, ...otherProps } = this.props
    const activeStyles = (href === window.location.pathname) ? `${activeClassName} bw2 inclusion-grape` : "";
    return (
      <div className={`${activeStyles} ${className} dib mr4-l`}>
        <IconLink {...otherProps} href={href} target={target} />
      </div>
    )
  }

}

export default NavLink