import React, {Component, Fragment} from "react"

// TODO: allow moving through course tabs with left and right arrows, rather than tabbing, so that tabs can be skipped

const OpenCourseTab = (props) => {
  const {course} = props
  return (
    <li role="presentation" className="flex">
      <button aria-selected="true" role='tab' id={course.title} tabIndex="0" className='flex items-center bl bt br bb-none bw-0-06rem b--mercury-gray br--top br3 pa3 mr-neg-offset w-14rem bg-white'>
        <img src={course.icon} className='w-1-5rem' alt='' />
        <div className="pl3 mw-10rem">
          <p className="tl fw7 dark-gray ma0">
            {course.title}
          </p>
        </div>
      </button>
    </li>
  )
}

const HiddenCourseTab = (props) => {
  const {course, changeSelectedCourse} = props
  return (
    <li role="presentation" className="flex">
      <button aria-selected="false" role='tab' id={course.title} tabIndex="0" onClick={() => changeSelectedCourse()} className='flex items-center bg-concrete-gray ba bw-0-06rem b--mercury-gray br--top br3 pa3 mr-neg-offset pointer'>
        <div className="w-1-5rem">
          <img src={course.icon} alt={course.title}></img>
        </div>
      </button>
    </li>
  )
}

class CourseTabs extends Component {
  state = { indexOfSelectedCourse: this.props.indexOfSelectedCourse }

  render () {
    const {courseDetails, aria_nav_label, changeSelectedCourse} = this.props
    const {indexOfSelectedCourse} = this.state
    return (
      <Fragment>
        <nav aria-label={aria_nav_label}>
            <ul role='tablist' aria-label="Courses" className='list flex flex-row flex-nowrap overflow-x-auto pl0 ma0'>
            {courseDetails.map((course, index) =>
            <Fragment key={index}>
              {index == indexOfSelectedCourse ?
                <OpenCourseTab course={course}/> :
                <HiddenCourseTab course={course} changeSelectedCourse={() => changeSelectedCourse(index)} />
              }
            </Fragment>
            )}
          </ul>
        </nav>
      </Fragment>
    )
  }
}

export default CourseTabs
