import React from 'react'
import Modules from './Modules';
import ResetCourseProgressModal from './ResetCourseProgressModal'

const SingleCourseProgress = (props) => {
  const { indexOfSelectedCourse, courseDetails, downloadCertificate,
    userId, canResetCourseProgress, isOpen,
    close, open, resetProgressUrl, companyId } = props
  const { id, progressPercentage, modules, 
    downloadCertificatePath, title} = courseDetails[indexOfSelectedCourse]

  return (
    <div className="flex flex-column flex-row-ns w-100">
      <section className="w-100 w-third-l">
        <p className="f5 lh-1-6 outer-space fw7 mt2 mb-3rem mv0-ns">
          Progress
          <span className={`db f4 fw5 ${progressPercentage < 100 ? "meteor-orange" : "jade-green"}`}>
            {progressPercentage}%
          </span>
        </p>
        { downloadCertificatePath &&
          <button 
            onClick={() => downloadCertificate(downloadCertificatePath)}
            className='square-btn filled-square-btn grape-btn link mv-3rem'>
            Download Certificate
          </button>
        }
        { canResetCourseProgress &&
          <button 
            onClick={open} 
            className='square-btn filled-square-btn grape-btn small lh-1-6 mt3rem mb4'>
            Reset Progress
          </button>
        }
      </section>
      <section className="w-100 w-two-thirds-l">
        <Modules modules={modules}/>
      </section>
      <ResetCourseProgressModal 
        courseId={id}
        userId={userId}
        resetProgressUrl={resetProgressUrl} 
        title={title}
        isOpen={isOpen}
        close={close}
        companyId={companyId}/>
    </div>
  )
}

export default SingleCourseProgress