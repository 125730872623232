import React from 'react'
import cn from 'classnames'

const Tooltip = (props) => {
  return (
    <div className={`tooltip pa3 bg-white ${props.status} ba`}>
        <p className='mv0 dark-gray-2 lh-1-6 f6 nowrap normal'>
          {props.children}
        </p>
    </div>
  )
}

const KeyLabel = (props) => {
  return (
    <p className='flex items-center f6 lh-1-6 black ma0 pb1'>
      <span className={`mr2 pa2 ${props.color}`} />
      {props.children}
    </p>
  )
}

const BarSegment = (props) => {
  const { share, count, status, usersPath } = props
  const statusClass = status.toLowerCase().replace(' ', '-')
  const visible = share > 0
  const segmentClasses = `white no-underline ph1 min-width-0-625rem h-100 tooltip-container bg-${statusClass}`
  const segmentWidth = `${share * 100}%`
  return (
    <a href={usersPath} className={cn({ [segmentClasses]: visible, 'dn': !visible })} style={{ 'width': segmentWidth}}>
      <div className='h-100 flex flex-wrap justify-center items-center overflow-hidden pointer-events-none'>
        <div className='w-pixel h-100 fl' />
        <p className='ma0 fl'>
          {count}
        </p>
      </div>
      <Tooltip status={statusClass}>
        <strong className='mr2'>{count}</strong>
        {status}
      </Tooltip>
    </a>
  )
}

const CourseProgressBar = (props) => {
  const { notStarted, inProgress, completed } = props
  const totalProgress = notStarted.count + inProgress.count + completed.count
  const shareFor = (progress) => totalProgress > 0 ? progress.count / totalProgress : totalProgress

  return (
    <figure className='course-progress ma0 w-100'>
      <div className='relative h2-5rem white b f6 lh-1-6 flex mb4'>
        <BarSegment status='Completed' count={completed.count} share={shareFor(completed)}     usersPath={completed.url}/>
        <BarSegment status='In Progress' count={inProgress.count} share={shareFor(inProgress)} usersPath={inProgress.url}/>
        <BarSegment status='Not started' count={notStarted.count} share={shareFor(notStarted)} usersPath={notStarted.url}/>
      </div>
      <figcaption>
        <KeyLabel color='bg-completed'>Completed</KeyLabel>
        <KeyLabel color='bg-in-progress'>In Progress</KeyLabel>
        <KeyLabel color='bg-not-started'>Not started</KeyLabel>
      </figcaption>
    </figure>
  )
}

export default CourseProgressBar