import React, {Component, Fragment} from "react"
import CourseProgressBar from './CourseProgressBar'


const NoSingleCourseProgress = () => {
  return (
    <p className="f5 outer-space fw7 mb4"> There is no progress to report for this course yet. </p>
  )
}

const DisabledCourseProgress = (props) => {
  const { manageCoursesUrl } = props

  return (
    <div className='center items-center flex flex-column justify-center'>
      <p className='f5 emperors-gray fw5 mb4 w-100'>This course has not been activated.</p>
      <a href={ manageCoursesUrl }className='square-btn filled-square-btn grape-btn no-underline'>Activate course</a>
    </div>
  )
}

const SingleCourseProgress = (props) => {
  return (
    <div className="w-100">
      <h3 className="f4 mt0 mb4">User Progress</h3>
      <CourseProgressBar {...props} />
    </div>
  )
}

class CompanyCourseProgressCard extends Component {

  render () {
    const { progress, loading, courseDetails, indexOfSelectedCourse, manageCoursesUrl } = this.props
    const progressExists = progress && progress.notStarted.count + progress.inProgress.count + progress.completed.count > 0
    const courseDisabled = courseDetails[indexOfSelectedCourse].disabled
    let content = <NoSingleCourseProgress />
    if(loading) {
      content = <Fragment />
    } else if(courseDisabled) {
      content = <DisabledCourseProgress manageCoursesUrl={manageCoursesUrl}/>
    } else if(progressExists) {
      content = <SingleCourseProgress {...progress} />
    }
    return (
      <div className="minh-17-1875rem-l flex soft-shadow pa3 pa4-ns br3">
        {content}
      </div>
    )
  }
}

export default CompanyCourseProgressCard
