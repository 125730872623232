import React, { Component } from 'react'
import cn from 'classnames';

const UploadedFeedback = (props) => {
  return (
    <p className='flex w-100 justify-center items-center f4 lh-title fw5 tc pa3'>
      <i className="pr2 fas fa-file"></i>
      <span className="pr2">
        {props.filename}
      </span>
    </p>
  )
}

const UploadPrompt = () => {
  return (
    <p className='f4 lh-title fw5 tc pointer-events-none'>
      Drag and drop .CSV of new users, or
      <span className="inclusion-grape ml1 bb fw7">upload</span>
  </p>
  )
}

export default class CSVInput extends Component {
  state = {
    uploadedFile: null,
  }

  setUploadedFileTo = (file) => this.setState({ uploadedFile: file })

  clear = () => this.setUploadedFileTo(null)

  readFileFromInput = (event) => this.setUploadedFileTo(event.target.files[0])

  render() {
    const { uploadedFile } = this.state
    return (
      <div className='ba b--dashed bw1 b--inclusion-grape bg-inclusion-grape-opacity-0-08 pa2'>
        <div className='flex justify-center items-center w-100 upload-btn-wrapper dim h4 relative'>
          {uploadedFile ? <UploadedFeedback filename={uploadedFile.name} /> : <UploadPrompt />}
          <input
            type="file"
            name="file"
            label='Upload CSV'
            placeholder='UploadCSV...'
            className='absolute absolute--fill o-0 pointer w-100'
            accept='text/csv'
            onChange={this.readFileFromInput}
            required={true}
          />
          <button
            type='button'
            className={`bg-transparent bn pointer absolute top-0 right-0 ma1 ma3-ns ${cn({invisible: !uploadedFile})}`}
            onClick={this.clear}
          >
            <i className='navy fal fa-times f5' />
          </button>
        </div>
      </div>
    )
  }
}
