import React, { Fragment } from 'react'
import asOpenable from '../../higher_order_components/asOpenable'
import withApiService from '../../higher_order_components/withApiService'
import ConfirmationModal from './ConfirmationModal'
import cn from 'classnames'

const PostButton = (props) => {
  const { open, close, isOpen, path, apiService, body, copy, children, className } = props

  const onClose = () => {
    props.close()
    window.location.reload()
  }

  const postAction = () => { apiService.postJson(body || {}, path).subscribe(onClose) }

  const buttonClassName = cn('pointer', className)

  return (
    <Fragment>
      <button
        className={className || ''}
        rel='nofollow'
        onClick={open}
        type="button"
      >
       {children}
      </button>
      <ConfirmationModal
        open={isOpen}
        onClose={ close }
        onConfirm={ postAction }
        heading='Are you sure?'
        question={copy}
      />
    </Fragment>
  )
}

export default withApiService(asOpenable(PostButton))