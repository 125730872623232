import React from 'react'
import EllipsisMenu from '../shared/EllipsisMenu'
import ManagerPromoteAction from './ManagerPromoteAction'
import ManagerDemoteAction from './ManagerDemoteAction'
import ManagerRemoveUserAction from './ManagerRemoveUserAction'
import ResetAccessTokenAction from './ResetAccessTokenAction'
import ResendInviteEmailsAction from './ResendInviteEmailsAction'

const Link = (props) => {
  const { path, label, method } = props
  return (
    <li>
      <a
        href={path}
        className='pointer db pa3 no-underline lh-1-6 f5 b outer-space inclusion-grape-hover'
        rel='nofollow'
        data-method={method}
        >
        {label}
      </a>
    </li>
  )
}

export default function ManagerActionsMenu(props) {
  const { links } = props
  const actionTypes = {
    link: Link,
    resendInviteEmails: ResendInviteEmailsAction,
    makeManager: ManagerPromoteAction,
    removeManager: ManagerDemoteAction,
    resetAccessToken: ResetAccessTokenAction,
    removeUser: ManagerRemoveUserAction
  }

  return (
    <EllipsisMenu buttonDescription='Open Manager Actions'>
      <nav
        aria-label='Manager Actions Dropdown'
        role='navigation'
      >
        <ul className='list pa0 ma0'>
          {
            links.map((link, i) => {
              const ComponentName = actionTypes[link.actionType]
              return (
                <ComponentName {...link} key={i} />
              )
            })
          }
        </ul>
      </nav>
    </EllipsisMenu>
  )
}
