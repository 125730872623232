import React from 'react'
import ReactDOM from 'react-dom'

const Overlay = (props) =>{
  const styles = {
    backgroundColor: props.color || '#000000',
    opacity: props.opacity || '0.5',
    zIndex: props.zIndex || '998',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px'
  }
  return props.open
    ?ReactDOM.createPortal(
      (
        <div className={'absolute w-100 h-100'} style={styles} onClick={props.onClick}>
        </div>
      ),
      document.body
    )
   : ''
}


export default Overlay