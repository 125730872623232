import React from "react"

export default function withLabel(Component) {
  return class LabelledComponent extends Component {

    render () {
      const { name, caption, marked, errors, ...componentProps} = this.props
      const hasErrors = errors && errors.length > 0
      return <div className="pv3">
        <label className="mid-gray fw7 f6 db mb2" htmlFor={name}>
          {caption}
          {marked && <b className="dark-red ml1">*</b>}
        </label>
        <Component name={name} {...componentProps} />
        {hasErrors && <label className="db mt2 f6 fw7 dark-red">{errors.join(', ')}</label>}
      </div>
    }

  }
}
