import React from 'react'
import Header from './Header'
import PlainLink from './PlainLink'
import NavLinkElements from './NavLinkElements'
import HamburgerMenu from './HamburgerMenu'

const UserHeader = (props) => {
  const { logoutPath, navLinks, ...otherProps } = props

  return (
    <Header {...otherProps} header_height={'tall_header_height'} authLink={<PlainLink href={logoutPath}>Log out</PlainLink>}>
      <div className='bg-third-color w-100'>
        <div className='center w-100 mw-78rem outer-space ph0 ph-1-5rem-ns'>
          <nav className='dn db-l flex-auto' role='navigation'>
            <NavLinkElements navLinks={navLinks} className="pt3 pb2" activeClassName="bb"/>
          </nav>
          <div className='dn-l pv3 relative w-100 flex justify-end'>
            <HamburgerMenu>
              <NavLinkElements navLinks={navLinks} className="center pb4" activeClassName=""/>
            </HamburgerMenu>
          </div>
        </div>
      </div>
    </Header>
  )
}

export default UserHeader

