import React from 'react'
import ModuleChecklistItem from './ModuleChecklistItem';

const Modules = (props) => {
  const { modules } = props

  return (
    <div className="flex flex-column flex-row-l bt bt-none-ns bl-ns b--french-gray pl4-ns">
      <div className="flex-auto mb-3rem">
        <h3 className="f4 fw7 lh-1-6 outer-space mv1-5rem mt0-ns">
          Modules
        </h3>
        { modules.map((moduleProps, index) => <ModuleChecklistItem key={index} {...moduleProps} />)}
      </div>
    </div>
  )
}

export default Modules