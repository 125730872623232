import React, { Fragment } from 'react'

const UserRowsSelect = (props) => {
  const viewUserRows = (event) => window.location = props.user_row_options.find(path => path.key == event.target.value).url
  return (
    <Fragment>
      <label className="dn" htmlFor="per_page">Select number of users to display:</label>
      <div className="relative w4 faIcon b--silver-chalice ba bw-0-03rem br-0-18rem pv2 ph3 mb3 mt2 mt0-l mb0-l">
        <select className="w-100 h-100 input-reset bn dib outer-space fw4 f6" onChange={viewUserRows} defaultValue={props.per_page} id="per_page" name="per_page" >
        {props.user_row_options.map(option =>
          <option key={option.key} value={option.key}>{option.key} rows</option>
        )
        }
        </select>
      </div>
    </Fragment>
  )
}
export default UserRowsSelect

