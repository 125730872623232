import React, { useMemo, useState } from 'react'
import ArchivedSeatRow from './ArchivedSeatRow';
import TableHead from './TableHead';
import TablePagination from './TablePagination';
import TableSearch from './TableSearch';

const ArchivedSeatsTable = ({initialData, restorePath, deletePath, bulkRestorePath, bulkDeletePath, available_seats}) => {
  const [data, setData] = useState(initialData || []);
  const [selected, setSelected] = useState(new Set([]));
  const pageSizes = [10, 25, 50, 100];
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const displayRestoreButtons = available_seats > 0;

  const displayBulkActions = useMemo(() => selected.size > 0, [selected]);

  // used to store pre-paginated search data
  const filteredData = useMemo(() => {
    let filteredData = data;
    if (search) {
      filteredData = data.filter( (row) => row.email.toLowerCase().includes(search.toLowerCase()) );
    }

    return filteredData;
  }, [data, search]);

  const pageCount = useMemo(() => Math.ceil(filteredData.length / pageSize), [
    filteredData,
    pageSize,
  ]);

  // used to store the records for the current page
  const dataToDisplay = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, currentPage, pageSize]);

  const changePage = (pageNumber) => {
    let newPageNumber = parseInt(pageNumber)
    if(isNaN(newPageNumber)) {
      return; // failed to parse int
    }

    setCurrentPage(Math.max(Math.min(newPageNumber, pageCount), 1));
  }

  const handleSelected = (id) => {
    if (selected.has(id)) {
      setSelected(new Set([...selected].filter((rowId) => rowId !== id)));
    } else {
      setSelected(new Set([...selected, id]));
    }
  }

  const handleSelectAll = () => {
    if(isAllDisplayDataSelected()) {
      // remove all currently displayed items
      const displayedSeatIds = dataToDisplay.map( seat => seat.id );
      setSelected(new Set([...selected].filter( id => !displayedSeatIds.includes(id) )));
    }
    else {
      // merge selected and current page of data
      setSelected(new Set([...selected, ...dataToDisplay.map(seat => seat.id)]));
    }
  }

  const handlePageSizeChanged = (event) => {
    const newPageSize = parseInt(event.target.value);
    if(!isNaN(newPageSize)) {
      setPageSize(newPageSize);
    }
  }

  const handlePageSelected = (event) => {
    changePage(event.target.value);
  }

  const handleNextPage = () => {
    changePage(currentPage + 1);
  }

  const handlePrevPage = () => {
    changePage(currentPage - 1);
  }

  const isAllDisplayDataSelected = () => {
    let diff = dataToDisplay.filter(seat => !selected.has(seat.id));
    return diff.length === 0;
  }

  const handleSearchChanged = (event) => {
    setSearch(event.target.value);
  }

  return (
    <div className='w-100 bg-white-90 shadow br2 mt-3rem pa3 pv4'>
      <TableSearch onChange={handleSearchChanged} />
      <div className='pb3'>
          <TableHead
            handleSelectAll={handleSelectAll}
            totalRows={data.length}
            selectedRows={selected}
            available_seats={available_seats}
            bulkDeletePath={bulkDeletePath}
            bulkRestorePath={bulkRestorePath}
          />
        <div>
          {
            dataToDisplay.map(seat => (
                <ArchivedSeatRow
                  key={seat.id}
                  selected={selected.has(seat.id)}
                  seat={seat}
                  toggleSelect={handleSelected}
                  displayActions={!displayBulkActions}
                  displayRestore={displayRestoreButtons}
                  deletePath={deletePath.replace(':id', seat.id)}
                  restorePath={restorePath.replace(':id', seat.id)}
                />
              )
            )
          }
        </div>
      </div>

      <TablePagination
        currentPage={currentPage}
        pageSizes={pageSizes}
        pageCount={pageCount}
        handlePageSelected={handlePageSelected}
        handlePageSizeChanged={handlePageSizeChanged}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
      />
   </div>
 )
}

export default ArchivedSeatsTable;
