import React, { Component } from 'react'
import Modal from 'react-responsive-modal'

class ConfirmationModal extends Component {
  render = () => {
    const { onConfirm, open, onClose, heading, question } = this.props
    return (
      <Modal open={ open } onClose={ onClose }  classNames={ { modal: 'br2 pa0', closeButton: 'dn' }} focusTrapped={true} >
        <div className='ph2 ph4-ns pv1-5rem pv4-ns'>
          <div className='f3 b lh-title mb1-5rem navy'>
            { heading }
          </div>
          <div className='lh-copy mw-26rem'>
            { question }
          </div>
          <div className='flex flex-wrap justify-between mt-3rem' >
            <button onClick={ onClose } className='square-btn outlined-square-btn grape-btn small pointer' >
              No
            </button>
            <button onClick={ onConfirm } className='square-btn filled-square-btn grape-btn small pointer'>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default ConfirmationModal