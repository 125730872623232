import React, {Component} from "react"
import SingleCourseProgress from './SingleCourseProgress'

const NoSingleCourseProgress = () => {
  return (
    <p className="f5 outer-space fw7 mb4"> There is no progress to report for this course yet. </p>
  )
}

class CourseProgressCards extends Component {

  downloadCertificate = (downloadCertificatePath) => {
    window.open(downloadCertificatePath, "_blank")
  }

  render () {
    const { indexOfSelectedCourse, courseDetails } = this.props
    const { modules } = courseDetails[indexOfSelectedCourse] || {}

    return (
      <div className="flex soft-shadow pa3 pa4-ns br3">
        { modules ?
        <SingleCourseProgress {...this.props} downloadCertificate={this.downloadCertificate} /> :
        <NoSingleCourseProgress />
        }
      </div>
    )
  }
}

export default CourseProgressCards

