import React, { Component } from 'react'
import AddUserDetail, { ClearButton } from '../../shared/IndividualUserDetailComponents'
import { orEmpty } from '../../shared/StringHelpers'

class IndividualInviteDetail extends Component {
  constructor(props) {
    super(props)
    const { email, error } = props

    this.state = {
      'email': orEmpty(email),
      'error': error
    }
  }

  clearFields = () => {
    this.setState({
      email: '',
      error: ''
    })
  }

  fieldChanged = (field, value) => {
    let newState = this.state
    newState[field] = value
    this.setState(newState)
  }

  render = () => {
    const { rowIndex } = this.props
    const { email, error } = this.state

    return (
      <div className='flex flex-wrap flex-nowrap-nst pb4 pt3-nst pb0-nst'>
        <div className='dn db-nst tc minw-1-25rem-ns order-2 order-0-ns pv3 fw5 f5 lh-1-6 dark-gray-5'>
          { rowIndex }
        </div>
        <div className='flex flex-wrap w-100 order-1 ph3-l'>
          <AddUserDetail 
            title='Email' 
            field='email' 
            error={ error ? error: '' }
            defaultValue={ orEmpty(email) }
            value={ email }
            onChange={ this.fieldChanged } 
          />
        </div>
        <div className='w-100 w-auto-nst tr order-0 order-2-nst pv3'>
          <ClearButton onClick={ this.clearFields } />
        </div>
      </div>
    )
  }
}

export default IndividualInviteDetail