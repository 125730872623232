import React, { Fragment } from 'react'
import asOpenable from '../../../higher_order_components/asOpenable'
import withApiService from '../../../higher_order_components/withApiService'
import ConfirmationModal from '../../shared/ConfirmationModal'

const DeleteUserInviteButton = (props) => {
  const {open, close, isOpen, path, apiService } = props

  const onClose = (response) => {
    props.close()
    window.location.reload()
  }

  const deleteAction = () => { apiService.deleteJson({}, path).subscribe(onClose) }

  return (
    <Fragment>
      <button
        className='square-btn filled-square-btn tall-poppy-red-btn small link dib'
        rel='nofollow'
        onClick={ open }
        type="button"
      >
        Delete
      </button>
      <ConfirmationModal
        open={ isOpen }
        onClose={ close }
        onConfirm={ deleteAction }
        heading='Are you sure?'
        question='Are you sure you want to delete this invite?'
      />
    </Fragment>
  )
}

export default withApiService(asOpenable(DeleteUserInviteButton))