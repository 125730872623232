import React, { Fragment } from 'react'
import withApiService from '../../../higher_order_components/withApiService'
import asOpenable from '../../../higher_order_components/asOpenable'
import ConfirmationModal from '../../shared/ConfirmationModal'

const ResendCompanyUserInviteAction = (props) => {
  const { apiService, open, isOpen, close, path, buttonText, question } = props

  const onClose = () => {
    props.close()
    window.location.reload()
  }

  const postAction = () => { apiService.postJson({}, path).subscribe(onClose) }

  return (
    <Fragment>
      <button
        className='square-btn outlined-square-btn grape-btn small link dib'
        rel='nofollow'
        onClick={ open }
        type="button"
      >
        { buttonText }
      </button>
      <ConfirmationModal
        open={ isOpen }
        onClose={ close }
        onConfirm={ postAction }
        heading='Are you sure?'
        question={ question }
      />
    </Fragment>
  )
}

export default withApiService(asOpenable(ResendCompanyUserInviteAction))