import React, {Component, Fragment} from 'react'
import Overlay from './Overlay'

export default class EllipsisMenu extends Component {
  state = {
    open: false
  }

  toggleOpen = () => {this.setState({open: !this.state.open})}

  render = () => {
    const { open } = this.state
    const { children, buttonDescription } = this.props
    return (
      <div className='tr relative'>
        <button 
          onClick={this.toggleOpen} 
          aria-label={buttonDescription} 
          aria-expanded={open}
          className='dib ph2 pv1 bg-transparent bn flex-none pointer dim mr2 mr0-ns' 
        >
          <i className='fas fa-ellipsis-v mid-gray'></i>
        </button>
        <div className={`absolute nowrap right-0 pa3 tl bg-white shadow-2 mt3 z-999  ${open ? 'db' : 'dn'}`}>
          {children}  
          <div tabIndex={0} onFocus={this.toggleOpen} />
        </div>
        <Overlay onClick={this.toggleOpen} open={open} opacity={'0'}/>
      </div>
    )
  }
}