import React from 'react'
import ManagerAction from './ManagerAction'

const ResetAccessTokenAction = (props) => {
  return (
    <ManagerAction
      heading='Are you sure?'
      question='This action will reset the single access token of a user. All invitation links that have been sent by this user will immediately become invalid.'
      { ...props }
    />
  )
}

export default ResetAccessTokenAction