import React from 'react'
import asOpenable from '../../higher_order_components/asOpenable'

const AccordionButton = (props) => {
  const { contentId, isOpen, toggle } = props
  const hiddenClass = 'dn'
  const element = document.getElementById(contentId)
  isOpen ? element.classList.remove(hiddenClass) : element.classList.add(hiddenClass)
  return (
    <button
      className='bg-transparent bn mid-gray'
      type='button' 
      onClick={toggle} 
      aria-controls={contentId} 
      aria-expanded={isOpen} 
      aria-describedby={`${contentId}-heading`}
    >
      <i 
        className={`fas fa-chevron-${isOpen ? 'down' : 'up'}`}
        aria-label={`${isOpen ? 'Hide' : 'Show'} Section`}
      />
    </button>
  )
}

const startsVisible = true
export default asOpenable(AccordionButton, startsVisible)